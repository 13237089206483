import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { I18nService } from 'src/app/shared/i18n.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  // Properties
  loginForm: FormGroup;
  currentLanguage: string;

  private $login: Subscription;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private i18nService: I18nService,
  ) { }

  ngOnInit(): void {
    // Set the login form
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(1)]],
    });

    this.currentLanguage = this.i18nService.getLanguage();
  }

  ngOnDestroy(): void {
    // unsubscribe to avoid memory leaks
    if (this.$login) {
      this.$login.unsubscribe();
    }
  }

  public login(): void {
    // Check if the form is valid
    if (!this.loginForm.valid) {
      return;
    }

    // Form disabled to prevent double request
    this.loginForm.disable();

    // Make the HTTP request
    this.$login = this.auth.login(this.loginForm.value).subscribe(
      res => {
        if (res.statusCode !== 200) {
          this.loginForm.enable();
          return;
        }
        this.router.navigate(['']);
      },
      err => {
        this.loginForm.enable();
      },
    );
  }

  public changeLanguage(key: string): void {
    this.i18nService.setLanguage(key);
    this.currentLanguage = key;
  }

  public getMessage(key: string): string {
    return this.i18nService.getMessage(key);
  }
}
