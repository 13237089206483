import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IData } from '../api/__types/user';

@Injectable({
  providedIn: 'root'
})
export class BehaviorUserService {
  private messageSource = new BehaviorSubject<IData | null>(null);
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  public changeMessage(message: IData): void {
    this.messageSource.next(message);
  }
}
