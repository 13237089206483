import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { UserService } from 'src/app/api/user.service';
import { I18nService } from 'src/app/shared/i18n.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  currentLanguage: string;
  errorMessage: { code: string, message: string };

  private $login: Subscription;
  private $signUp: Subscription;

  constructor(
    private i18nService: I18nService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // Set the register form
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      alias: ['', [Validators.required, Validators.minLength(5)]],
      password: ['', [Validators.required, Validators.minLength(5)]],
      repassword: ['', [Validators.required, Validators.minLength(5)]],
      termsAccept: [false, [Validators.required]],
    });

    this.currentLanguage = this.i18nService.getLanguage();
    this.onChangeInput();
  }

  ngOnDestroy(): void {
    if (this.$login) {
      this.$login.unsubscribe();
    }

    if (this.$signUp) {
      this.$signUp.unsubscribe();
    }
  }

  private onChangeInput(): void {
    this.registerForm.valueChanges.subscribe(() => this.errorMessage = undefined);
  }

  private login({email, password}: {email: string, password: string}): void {
    this.$login = this.auth.login({ email, password }).subscribe(
      res => {
        this.router.navigate(['']);
      },
      err => {
        this.errorMessage = err;
      },
    );
  }

  public signUp(): void {
    // Check if the form is valid
    if (!this.registerForm.valid) {
      return;
    }

    this.registerForm.disable();

    if (!this.registerForm.value.termsAccept) {
      this.registerForm.enable();
      this.registerForm.controls.termsAccept.setErrors({incorrect: true});
      return;
    }

    if (this.registerForm.value.password !== this.registerForm.value.repassword) {
      this.registerForm.enable();
      this.registerForm.controls.repassword.setErrors({incorrect: true});
      return;
    }

    this.$signUp = this.userService.createUser(this.registerForm.value).subscribe(
      res => {
        if (res.statusCode !== 200) {
          this.registerForm.enable();
          return;
        }
        this.login(this.registerForm.value);
      },
      err => {
        this.registerForm.enable();
        this.errorMessage = err;
      },
    );
  }

  public changeLanguage(key: string): void {
    this.i18nService.setLanguage(key);
    this.currentLanguage = key;
  }

  public getMessage(key: string): string {
    return this.i18nService.getMessage(key);
  }
}
