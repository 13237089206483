import { Injectable } from '@angular/core';
import { I18nMessages } from './__types';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private readonly LANGUAGE_LOCAL_STORAGE_KEY = 'app_language';

  private readonly i18n: { [lang: string]: I18nMessages } = {
    es: {
      // Common
      changeLanguage: 'Cambiar idioma',
      latam: 'América Latina',
      submit: 'Enviar',
      address: 'Dirección',
      format: 'Formato',
      size: 'Tamaño',
      paper: 'Papel',
      productName: 'Nombre',
      units: '',

      // Login
      email: 'Correo electrónico',
      password: 'Contraseña',
      login: 'Iniciar sesión',
      forgot: '¿Olvidó su contraseña?',
      logout: 'Cerrar sesión',
      nextStep: 'Siguiente',
      prevStep: 'Anterior',

      // Sign up
      signUp: 'Regístrate',
      signUpTitle: '¿No tienes una cuenta?',
      loginLabel: '¿Ya tienes cuenta? Inicia sesión aquí',
      rePassword: 'Vuelve a escribir la contraseña',
      passwordMatchError: 'Contraseñas no coinciden',
      termsError: 'Debes aceptar los términos y condiciones',
      acceptTerms: 'Acepto los términos y condiciones',

      // Profile
      accountSettings: 'Configurar cuenta',

      // Onboarding
      onboardingTitle: 'Hola, bienvenido',
      onboardingDescription: 'En pocos minutos se completará el registro. Es rápido y sencillo. ¡Vamos!',

      // Biography section
      biographyTitle: 'Añadir biografía',
      biographyDescription: `
        Di algo sobre ti. Te ayudará a lanzar tu obra de arte en nuestro sitio web y los motores de búsqueda, y a que la gente conozca más sobre ti, tus motivaciones, técnicas que utilizas, tu procedencia, o lo que quieras comunicar.
      `,
      chooseAvatar: 'Elige una imagen para tu perfil',
      changeYourAvatar: 'Cambiar foto',
      uploadImage: 'Subir imagen',
      chooseAvatarIndications: 'Solo se permiten formatos .jpg o .png',
      biographyPlaceholder: 'Preséntate a ti mismo. Debe contener al menos 50 caracteres y máximo 1,000 caracteres.',
      biographyErrorMessage: 'Biografía debe contener al menos 50 caracteres y máximo 1000 caracteres',

      // General information
      generalInfoTitle: 'Información general',
      fullName: 'Nombre completo',
      stageName: 'Nombre artístico',
      country: 'País',
      city: 'Ciudad',
      phoneNumber: 'Número de télefono',
      birthDate: 'Fecha de nacimiento',
      gender: 'Sexo',
      male: 'Hombre',
      female: 'Mujer',

      // Receipt information
      receiptInfo: 'Formas de pago',
      receiptForms: 'Métodos de recibos',
      international: 'Internacional',
      directBankTransfer: 'Transferencia bancaría directa',

      // Pending approval
      greeting: 'Hola',
      pendingApprovalSubtitle: `
        Para poder continuar con el proceso, debes enviarnos imágenes de las obras que quieras publicar en la web.
      `,
      pendingApprovalStep1: `
        Debes enviarlas a artistas@kuelga.com, puedes usar cualquier servicio para compartir archivos como Dropbox, Google Drive, WeTransfer, etc. Se necesita un mínimo de 5 o 6 obras para ser publicadas en la web, por lo que es mejor enviar una cantidad mayor.
      `,

      // Bank info
      accountNumber: 'Número de cuenta',
      bankName: 'Nombre del banco',
      accountInfo: 'Por favor llena los siguientes campos requeridos',

      // Dashboard
      enterDateRange: 'Seleccione un rango',
      getReport: 'Visualizar',
      completedSales: 'Ventas completadas',
      summaryByArt: 'Ventas por obra',
      summaryByQuantity: 'Cantidad de obras vendidas (unidades)',
      firstCase: 'Para visualizar tus ventas debes seleccionar un período de fechas',
      emptyCase: 'No hay registros de ventas, prueba otro rango de fechas',
      updateCurrency: 'Cambiar moneda',
      orderNo: 'N° Orden',
      orderDate: 'Fecha creada',
      orderCompletedDate: 'Fecha completada',
      orderTotal: 'Total',
      percentComission: '% comisión*',
      percentComissionNote: '* Comisión de 20% por venta directa a Kuelga.com<br>Comisión de 10% por venta de terceros (Falabella, Ripley, Sin Envolturas)',
    },
    en: {
      // Common
      changeLanguage: 'Change language',
      latam: 'Latin America',
      submit: 'Submit',
      address: 'Address',
      format: 'Format',
      size: 'Size',
      paper: 'Paper',
      productName: 'Name',
      units: '',

      email: 'Email',
      password: 'Password',
      forgot: 'Forgot your password?',
      login: 'Login',
      logout: 'Logout',
      nextStep: 'Next step',
      prevStep: 'Previous step',

      // Sign up
      signUpTitle: 'Sign up here',
      signUp: 'Sign up',
      loginLabel: 'Do you already have an account? Login here',
      rePassword: 'Write the password, again',
      passwordMatchError: 'Passwords do not match',
      termsError: 'You must agree to terms and conditions',
      acceptTerms: 'I accept the terms and conditions',

      // Profile
      accountSettings: 'Account settings',

      // Onboarding
      onboardingTitle: 'Hi, welcome',
      onboardingDescription: `In a few minutes the registration will be completed. It is quick and easy. Let's go!`,

      // Biography section
      biographyTitle: 'Add biography',
      biographyDescription: `
        Say something about you. It will help people know more about you, your motivations, techniques used, where you come from, or whatever you want to communicate. It also helps search engines find you faster.
      `,
      chooseAvatar: 'Choose an image for your profile',
      uploadImage: 'Upload image',
      changeYourAvatar: 'Change photo',
      chooseAvatarIndications: 'Solo se permiten formatos .jpg o .png',
      biographyPlaceholder: 'Introduce yourself. It must contain at least 50 characters and a maximum of 1,000 characters.',
      biographyErrorMessage: 'Biography must contain at least 50 characters and a maximum of 1000 characters',

      // General information
      generalInfoTitle: 'General information',
      fullName: 'Full name',
      stageName: 'Stage name',
      country: 'Country',
      city: 'City',
      phoneNumber: 'Phone number',
      birthDate: 'Birth date',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',

      // Receipt information
      receiptInfo: 'Payment Methods',
      receiptForms: 'Receipt methods',
      international: 'International',
      directBankTransfer: 'Direct bank transfer',

      // Pending approval
      greeting: 'Hi',
      pendingApprovalSubtitle: `
        In order to continue with the process, you must send us images of the works you want to publish on the web.
      `,
      pendingApprovalStep1: `
        You must send them to artists@kuelga.com, you can use any file sharing service such as Dropbox, Google Drive, WeTransfer, etc. A minimum of 5 or 6 works is needed to be published on the web, so it is better to send a larger quantity.
      `,

      // Bank info
      accountNumber: 'Account number',
      bankName: 'Bank name',
      accountInfo: 'Please fill in the following required fields',

      // Dashboard
      enterDateRange: 'Select a range',
      getReport: 'Visualize',
      completedSales: 'Sales completed',
      summaryByArt: 'Summary of sales by art',
      summaryByQuantity: 'Number of arts sold (units)',
      firstCase: 'To view your sales you must select a date period',
      emptyCase: 'No sales records, try another date range',
      updateCurrency: 'Change currency',
      orderNo: 'Order No.',
      orderDate: 'Date created',
      orderCompletedDate: 'Date completed',
      orderTotal: 'Total',
      percentComission: '% commission*',
      percentComissionNote: '* 20% commission for direct sales to Kuelga.com<br>10% commission for third party sales (Falabella, Ripley, without wraps)',
    },
  };

  constructor() { }

  private getMessages(lang?: string): I18nMessages {
    return {
      ...this.i18n.es,
      ...this.i18n[lang || 'es'],
    };
  }

  public getMessage(key: string, lang?: 'en' | 'es'): string {
    return this.getMessages(lang || this.getLanguage())[key] || key;
  }

  public setLanguage(lang?: string): void {
    localStorage.setItem(this.LANGUAGE_LOCAL_STORAGE_KEY, lang === 'es' ? 'es' : 'en');
  }

  public getLanguage(): string {
    return localStorage.getItem(this.LANGUAGE_LOCAL_STORAGE_KEY) || 'es';
  }
}
