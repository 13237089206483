import { IPaymentGroup, IPaymentMethod } from '../__types/payment';

export const paymentGroups: Array<IPaymentGroup> = [
  {
    id: 'peru',
    i18nKey: 'Perú',
  },
  {
    id: 'colombia',
    i18nKey: 'Colombia',
  },
  {
    id: 'chile',
    i18nKey: 'Chile',
  },
  {
    id: 'international',
    i18nKey: 'international',
  },
];

export const paymentMethods: Array<IPaymentMethod> = [
  // International
  {
    id: 'paypal',
    name: 'PayPal',
    imageUrl: 'paypal.svg',
    groupId: 'international',
    forms: [{
      type: 'string',
      name: 'paypal-user',
      i18nLabel: 'email',
      required: true,
    }],
  },
  {
    id: 'internationalDirectBankTransfer',
    name: 'TransferWise',
    groupId: 'international',
    imageUrl: 'TransferWise_logo.svg',
    forms: [
      {
        type: 'string',
        name: 'bank-name',
        i18nLabel: 'bankName',
        required: true,
      },
      {
        type: 'string',
        name: 'account-number',
        i18nLabel: 'accountNumber',
        required: true,
      },
      {
        type: 'string',
        name: 'iban',
        i18nLabel: 'IBAN',
        required: true,
      },
      {
        type: 'string',
        name: 'swift',
        i18nLabel: 'BIC / Swift',
        required: true,
      },
      {
        type: 'string',
        name: 'address',
        i18nLabel: 'address',
        required: true,
      },
    ],
  },

  // Chile
  {
    id: 'chileDirectBankTransfer',
    name: 'directBankTransfer',
    groupId: 'chile',
    imageUrl: 'bank-icon.svg',
    forms: [
      {
        type: 'string',
        name: 'bank-name',
        i18nLabel: 'bankName',
        required: true,
      },
      {
        type: 'string',
        name: 'account-number',
        i18nLabel: 'accountNumber',
        required: true,
      },
      {
        type: 'string',
        name: 'RUT',
        i18nLabel: 'RUT',
        required: true,
      },
    ],
  },

  // Colombia
  {
    id: 'colombiaDirectBankTransfer',
    name: 'directBankTransfer',
    groupId: 'colombia',
    imageUrl: 'bank-icon.svg',
    forms: [
      {
        type: 'string',
        name: 'bank-name',
        i18nLabel: 'bankName',
        required: true,
      },
      {
        type: 'string',
        name: 'account-number',
        i18nLabel: 'accountNumber',
        required: true,
      },
      {
        type: 'string',
        name: 'RUT',
        i18nLabel: 'RUT',
        required: true,
      },
    ],
  },

  // Perú
  {
    id: 'peruDirectBankTransfer',
    name: 'directBankTransfer',
    groupId: 'peru',
    imageUrl: 'bank-icon.svg',
    forms: [
      {
        type: 'string',
        name: 'bank-name',
        i18nLabel: 'bankName',
        required: true,
      },
      {
        type: 'string',
        name: 'account-number',
        i18nLabel: 'accountNumber',
        required: true,
      },
      {
        type: 'string',
        name: 'cuenta-interbancaria',
        i18nLabel: 'N° Cuenta interbancario',
        required: true,
      },
      {
        type: 'string',
        name: 'dni',
        i18nLabel: 'DNI',
        required: true,
      },
    ],
  }
];
