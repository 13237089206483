import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IUserResponse } from './__types/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) { }

  public getUserProfile(): Observable<IUserResponse> {
    const url = `${environment.baseApiUrl}jwt-auth/v1/artist/profile`;

    return new Observable<IUserResponse>(observer => {
      this.http.get<IUserResponse>(url).subscribe(
        res => {
          if (res.statusCode !== 200) {
            observer.error({
              statusCode: res.statusCode,
              errorCode: res.code,
            });
            observer.complete();
            return;
          }

          observer.next(res);
          observer.complete();
        },
        err => {
          observer.error({ code: err.error.code, message: err.error.message });
        },
      );
    });
  }

  public createUser({ email, password, alias }: { email: string, password: string, alias: string }): Observable<any> {
    const url = `${environment.baseApiUrl}kuelga/v1/register`;
    const data = { email, password, alias };

    return new Observable<any>(observer => {
      this.http.post<any>(url, data).subscribe(
        res => {
          if (res.statusCode !== 200) {
            observer.error({
              statusCode: res.statusCode,
              errorCode: res.code,
            });
            observer.complete();
            return;
          }

          observer.next(res);
          observer.complete();
        },
        err => {
          observer.error({ code: err.error.code, message: err.error.message });
        },
      );
    });
  }

  public updateUser(data: any): Observable<any> {
    const url = `${environment.baseApiUrl}jwt-auth/v1/artist/profile`;

    return new Observable<any>(observer => {
      this.http.put<any>(url, data).subscribe(
        res => {
          if (res.statusCode !== 200) {
            observer.error({
              statusCode: res.statusCode,
              errorCode: res.code,
            });
            observer.complete();
            return;
          }

          observer.next(res);
          observer.complete();
        },
        err => {
          observer.error({ code: err.error.code, message: err.error.message });
        },
      );
    });
  }

  public makeR1Role(): Observable<any> {
    const url = `${environment.baseApiUrl}jwt-auth/v1/artist/r1`;

    return new Observable<any>(observer => {
      this.http.put<any>(url, {}).subscribe(
        res => {
          if (res.statusCode !== 200) {
            observer.error({
              statusCode: res.statusCode,
              errorCode: res.code,
            });
            observer.complete();
            return;
          }

          observer.next(res);
          observer.complete();
        },
        err => {
          observer.error({ code: err.error.code, message: err.error.message });
        },
      );
    });
  }

  public makeMigratedArtist(): Observable<any> {
    const url = `${environment.baseApiUrl}jwt-auth/v1/artist/migrated`;

    return new Observable<any>(observer => {
      this.http.put<any>(url, {}).subscribe(
        res => {
          if (res.statusCode !== 200) {
            observer.error({
              statusCode: res.statusCode,
              errorCode: res.code,
            });
            observer.complete();
            return;
          }

          observer.next(res);
          observer.complete();
        },
        err => {
          observer.error({ code: err.error.code, message: err.error.message });
        },
      );
    });
  }
}
