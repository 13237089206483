import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-avatar-cropper',
  templateUrl: './avatar-cropper.component.html',
  styleUrls: ['./avatar-cropper.component.scss']
})
export class AvatarCropperComponent implements OnInit {
  // Properties
  avatarRes: { croppedImage: string } = { croppedImage: '' };

  constructor(
    public dialogRef: MatDialogRef<AvatarCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imageChangedEvent: any },
  ) { }

  ngOnInit(): void { }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.avatarRes.croppedImage = event.base64;
  }

  public fileChangeEvent(event: any): void {
    this.data.imageChangedEvent = event;
  }
}
