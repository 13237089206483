<div class="main-wrapper">
  <mat-card class="register-wrapper">
    <div style="text-align: center; margin-bottom: 16px;">
      <img width="100" src="https://kuelga.com/wp-content/uploads/2021/04/kuelga-app.jpg">
    </div>

    <form [formGroup]="registerForm" (ngSubmit)="signUp()">
      <mat-form-field appearance="outline" class="register-input">
        <mat-label>{{ getMessage('email') }}</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>

      <mat-form-field appearance="outline" class="register-input">
        <mat-label>{{ getMessage('Alias') }}</mat-label>
        <input matInput formControlName="alias">
      </mat-form-field>

      <mat-form-field appearance="outline" class="register-input">
        <mat-label>{{ getMessage('password') }}</mat-label>
        <input matInput type="password" formControlName="password">
      </mat-form-field>

      <mat-form-field appearance="outline" class="register-input">
        <mat-label>{{ getMessage('rePassword') }}</mat-label>
        <input matInput type="password" formControlName="repassword">
        <mat-error *ngIf="registerForm.controls.repassword.invalid && registerForm.controls.repassword.value !== registerForm.controls.password.value">{{ getMessage('passwordMatchError') }}</mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="termsAccept" [style]="{'margin-top': '8px', 'margin-bottom': registerForm.controls.termsAccept.invalid ? '0' : '16px'}">
        <a href="https://kuelga.com/terminos-y-condiciones-de-artista/" target="_blank">{{ getMessage('acceptTerms') }}</a>
      </mat-checkbox>
      <mat-error style="margin-bottom: 16px;" *ngIf="registerForm.controls.termsAccept.invalid">{{ getMessage('termsError') }}</mat-error>

      <div fxLayout="row" fxLayout.lt-md="column-reverse" fxLayoutAlign="space-between" fxLayoutAlign.lt-md="center center">
        <button mat-flat-button color="primary" [disabled]="registerForm.disabled">{{ getMessage('signUp') }}</button>
      </div>

      <p *ngIf="errorMessage" style="font-size: 12px; margin: 16px 0; color: red">{{ errorMessage.message }}</p>
    </form>
  </mat-card>

  <div class="language-wrapper">
    <a mat-button [routerLink]="['/login']" [ngStyle.lt-md]="{'margin-top': '14px'}">{{ getMessage('loginLabel') }}</a>
  </div>

  <div class="language-wrapper">
    <button mat-button (click)="changeLanguage('en')">ENG</button>
    <button mat-button (click)="changeLanguage('es')">ESP</button>
  </div>
</div>
