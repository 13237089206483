<div *ngIf="loading" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
  <img height="160" src="../../../assets/img/animation_500_knfk9kn8.gif" />
</div>

<div *ngIf="first && !loading" style="display: flex; justify-content: center; padding: 30px 0;">
  <mat-card>
    <div style="display: flex; flex-direction: column; align-items: center;">
      <div style="width: 80px; margin-bottom: 12px;" [ngStyle.lt-md]="{'width': '60px'}">
        <img style="width: 100%;" src="../../../assets/img/estadisticas.svg" />
      </div>
      <div style="max-width: 250px;">
        <p style="text-align: center; margin: 0;">{{ getMessage('firstCase') }}</p>
      </div>
    </div>
  </mat-card>
</div>

<div *ngIf="emptyCase && !loading" style="display: flex; justify-content: center; padding: 30px 0;">
  <mat-card>
    <div style="display: flex; flex-direction: column; align-items: center;">
      <div style="width: 80px; margin-bottom: 12px;" [ngStyle.lt-md]="{'width': '60px'}">
        <img style="width: 100%;" src="../../../assets/img/estadisticas.svg" />
      </div>
      <div style="max-width: 250px;">
        <p style="text-align: center; margin: 0;">{{ getMessage('emptyCase') }}</p>
      </div>
    </div>
  </mat-card>
</div>

<div *ngIf="canRenderGraph() && !loading" style="margin-top: 24px; display: flex; flex-direction: column;">
  <div style="width: 100%; display: flex;" [ngStyle.lt-md]="{'flex-direction': 'column'}">
    <div style="width: 48%; border: solid 1px;" [ngStyle.lt-md]="{'width': '100%'}">
      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
        [legend]="barChartLegend" [chartType]="barChartType"></canvas>
    </div>

    <div style="width: 48%; border: solid 1px; margin-left: 4%;" [ngStyle.lt-md]="{'width': '100%', 'margin-left': 0, 'margin-top': '30px'}">
      <canvas baseChart [datasets]="batArtChartData" [labels]="barArtChartLabels" [options]="barArtChartOptions"
        [legend]="barChartLegend" [chartType]="barChartType"></canvas>
    </div>
  </div>

  <div style="width: 100%; display: flex;" [ngStyle.lt-md]="{'flex-direction': 'column'}">
    <div style="width: 48%; border: solid 1px; margin-top: 30px;" [ngStyle.lt-md]="{'width': '100%', 'margin-left': 0, 'margin-top': '30px'}">
      <canvas baseChart [datasets]="batArtSalesChartData" [labels]="barArtSalesChartLabels"
        [options]="barArtSalesChartOptions" [legend]="barChartLegend" [chartType]="barChartType"></canvas>
    </div>
  </div>

  <div *ngIf="dataSource.length > 0" style="width: 100%; margin-top: 30px;">
    <p [innerHTML]="getMessage('percentComissionNote')"></p>
  </div>

  <div *ngIf="dataSource.length > 0" style="width: 100%; margin-top: 30px;" [ngStyle.lt-md]="{'width': '100%', 'margin-top': '24px', 'padding': '0'}">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1" style="width: 100%;">
      <ng-container matColumnDef="finishedAt">
        <th mat-header-cell *matHeaderCellDef> {{ getMessage('orderCompletedDate') }} </th>
        <td mat-cell *matCellDef="let sale"> {{sale.finishedAt}} </td>
      </ng-container>

      <ng-container matColumnDef="paper">
        <th mat-header-cell *matHeaderCellDef> {{ getMessage('paper') }} </th>
        <td mat-cell *matCellDef="let sale"> {{sale.paper}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef> {{ getMessage('size') }} </th>
        <td mat-cell *matCellDef="let sale"> {{sale.size}} </td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef> {{ getMessage('productName') }} </th>
        <td mat-cell *matCellDef="let sale"> {{sale.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="totalPEN">
        <th mat-header-cell *matHeaderCellDef> {{ getMessage('orderTotal') }} (PEN) </th>
        <td mat-cell *matCellDef="let sale"> {{sale.totalPEN | currency: 'PEN':'S/ '}} </td>
      </ng-container>

      <ng-container matColumnDef="totalCOP">
        <th mat-header-cell *matHeaderCellDef> {{ getMessage('orderTotal') }} (COP) </th>
        <td mat-cell *matCellDef="let sale"> {{sale.totalCOP | currency: 'COP':'$'}} </td>
      </ng-container>

      <ng-container matColumnDef="totalCLP">
        <th mat-header-cell *matHeaderCellDef> {{ getMessage('orderTotal') }} (CLP) </th>
        <td mat-cell *matCellDef="let sale"> {{sale.totalCLP | currency: 'CLP':'$'}} </td>
      </ng-container>

      <ng-container matColumnDef="percent">
        <th mat-header-cell *matHeaderCellDef> {{ getMessage('percentComission') }} </th>
        <td mat-cell *matCellDef="let sale"> {{sale.percent}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
