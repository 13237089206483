import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nPipe } from './pipes/i18n.pipe';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { JWTInterceptor } from './http.interceptor';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AvatarCropperComponent } from './pages/onboarding/avatar-cropper/avatar-cropper.component';
import { RegisterComponent } from './pages/register/register.component';
import { PendingApprovalComponent } from './pages/pending-approval/pending-approval.component';
import { ChartComponent } from './components/chart/chart.component';
import { ChartsModule } from 'ng2-charts';
import { TitleCasePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    HomeComponent,
    OnboardingComponent,
    I18nPipe,
    DashboardComponent,
    AvatarCropperComponent,
    RegisterComponent,
    PendingApprovalComponent,
    ChartComponent,
  ],
  imports: [
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    LottieModule.forRoot({ player: () => player }),
    ImageCropperModule,
    ChartsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true,
    },
    TitleCasePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
