import { Component, OnDestroy, OnInit } from '@angular/core';
import { Chart, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { SalesService } from 'src/app/api/sales.service';
import { IData, IRawData, Sale, TableSales } from 'src/app/api/__types/sales';
import { I18nService } from 'src/app/shared/i18n.service';
import * as moment from 'moment';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy {
  // Observables
  $salesData: Subscription;

  // Props
  loading = false;

  // Public data
  currentLanguage: string;
  salesData: IData = null;
  first = true;
  emptyCase = false;

  barChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        labels: {
          title: {
            font: {
              weight: 'bold',
              size: 14,
            },
          },
        },
        formatter: (value: number, context): string => {
          return `$${value.toFixed(2)} USD`;
        },
      },
    },
  };

  barArtChartOptions: ChartOptions = {
    responsive: true,
  };

  barArtSalesChartOptions: ChartOptions = {
    responsive: true,
  };

  monthEngNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  barChartLabels: Label[] = [];
  barArtChartLabels: Label[] = [];
  barArtSalesChartLabels: Label[] = [];

  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartData: ChartDataSets[] = [];
  batArtChartData: ChartDataSets[] = [];
  batArtSalesChartData: ChartDataSets[] = [];

  displayedColumns: string[] = [
    'finishedAt',
    'paper',
    'size',
    'productName',
    'totalPEN',
    'totalCOP',
    'totalCLP',
    'percent',
  ];

  dataSource: TableSales[] = [];

  constructor(
    private salesService: SalesService,
    private i18nService: I18nService,
    private titleCasePipe: TitleCasePipe,
  ) {}

  ngOnInit(): void {
    Chart.plugins.register(ChartDataLabels);
  }

  ngOnDestroy(): void {
    try {
      this.$salesData.unsubscribe();
    } catch (ex) {}
  }

  private formatSize(size: string): string {
    const regexNumbs = /\d+/g;
    return size.match(regexNumbs).join('x');
  }

  private getUSDValue(amount: number, currency: string): number {
    const currencyProp = `forex${this.titleCasePipe.transform(currency)}Rate`;
    return amount / ((this.salesData[currencyProp] / this.salesData.forexUsdRate));
  }

  private parseResponse(data: IRawData): void {
    const sales: Sale[] = data.sales.map((sale) => ({
      id: parseInt(sale.id, 10),
      createdAt: new Date(sale.created_at),
      finishedAt: new Date(sale.finished_at),
      updatedAt: new Date(sale.updated_at),
      orderId: parseInt(sale.order_id, 10),
      productId: parseInt(sale.product_id, 10),
      total: parseFloat(parseFloat(sale.total).toFixed(2)),
      variationId: parseInt(sale.variation_id, 10),
      orderStatus: 'completed',
      format: sale.format,
      paper: sale.paper,
      productName: sale.product_name,
      size: this.formatSize(sale.size),
      currency: sale.currency,
    }));

    this.salesData = {
      forexClpRate: parseFloat(data.forex_clp_rate),
      forexCopRate: parseFloat(data.forex_cop_rate),
      forexPenRate: parseFloat(data.forex_pen_rate),
      forexUsdRate: parseFloat(data.forex_usd_rate),
      sales,
    };

    const tableSales: TableSales[] = data.sales.map(sale => ({
      orderId: parseInt(sale.order_id, 10),
      finishedAt: moment(sale.finished_at).format('DD-MMM-YYYY'),
      paper: sale.paper.replace(/-/g, ' '),
      size: this.formatSize(sale.size),
      productName: sale.product_name,
      totalPEN: sale.currency === 'PEN' ? parseFloat(sale.total).toFixed(2) : null,
      totalCLP: sale.currency === 'CLP' ? parseFloat(sale.total).toFixed(2) : null,
      totalCOP: sale.currency === 'COP' ? parseFloat(sale.total).toFixed(2) : null,
      percent: sale.percent,
    }));

    this.dataSource = tableSales;
  }

  private createGraph(): void {
    const months: Set<string> = new Set();
    const values: Map<string, number> = new Map();

    if (!this.salesData) {
      return;
    }

    for (const sale of this.salesData.sales) {
      const month = this.monthEngNames[sale.finishedAt.getMonth()];
      months.add(month);

      let total = this.getUSDValue(sale.total, sale.currency);
      if (values.has(month)) {
        total += values.get(month);
      }
      values.set(month, total);
    }

    this.barChartLabels = Array.from(months);

    if (this.salesData.sales.length === 0) {
      this.emptyCase = true;
    } else {
      this.emptyCase = false;
    }

    this.barChartData = [
      {
        data: Array.from(values, ([, value]) => parseFloat(value.toFixed(2))),
        label: this.getMessage('completedSales'),
        backgroundColor: '#00bcd4',
        borderColor: '#00bcd4',
        hoverBackgroundColor: '#029baf',
        hoverBorderColor: '#029baf',
      },
    ];
  }

  private createArtGraph(): void {
    const artNames: Set<string> = new Set();
    const values: Map<string, number> = new Map();

    for (const sale of this.salesData.sales) {
      artNames.add(sale.productName);
      let total = this.getUSDValue(sale.total, sale.currency);
      if (values.has(sale.productName)) {
        total += values.get(sale.productName);
      }
      values.set(sale.productName, total);
    }

    this.barArtChartOptions = {
      responsive: true,
      plugins: {
        datalabels: {
          anchor: 'end',
          labels: {
            title: {
              font: {
                weight: 'bold',
                size: 14,
              },
            },
          },
          formatter: (value: number, context): string => {
            return `$${value.toFixed(2)} USD`;
          },
        },
      },
    };

    this.barArtChartLabels = Array.from(artNames);
    this.batArtChartData = [{
      data: Array.from(values, ([, value]) => parseFloat(value.toFixed(2))),
      label: this.getMessage('summaryByArt'),
      backgroundColor: '#0dd400',
      borderColor: '#0dd400',
      hoverBackgroundColor: '#0bab01',
      hoverBorderColor: '#0bab01',
    }];
  }

  private createArtSalesGraph(): void {
    const artNames: Set<string> = new Set();
    const values: Map<string, number> = new Map();

    for (const sale of this.salesData.sales) {
      artNames.add(sale.productName);
      let total = 1;
      if (values.has(sale.productName)) {
        total += values.get(sale.productName);
      }
      values.set(sale.productName, total);
    }

    this.barArtSalesChartOptions = {
      responsive: true,
      plugins: {
        datalabels: {
          anchor: 'end',
          labels: {
            title: {
              font: {
                weight: 'bold',
                size: 14,
              },
            },
          },
          formatter: (value: number, context): string => {
            return `${value}`;
          },
        },
      },
    };

    this.barArtSalesChartLabels = Array.from(artNames);
    this.batArtSalesChartData = [{
      data: Array.from(values, ([, value]) => parseFloat(value.toFixed(2))),
      label: this.getMessage('summaryByQuantity'),
      backgroundColor: '#ffe000',
      borderColor: '#ffe000',
      hoverBackgroundColor: '#d0b702',
      hoverBorderColor: '#d0b702',
    }];
  }

  public getReports(start: number, end: number): void {
    this.loading = true;
    this.$salesData = this.salesService.getSales(start, end).subscribe(
      (res) => {
        if (res.statusCode !== 200) {
          return;
        }
        if (this.first) {
          this.first = false;
        }
        this.loading = false;
        this.parseResponse(res.data);
        this.createGraph();

        if (res.data.sales.length > 0) {
          this.createArtGraph();
          this.createArtSalesGraph();
        }
      },
      (err) => {}
    );
  }

  public canRenderGraph(): boolean {
    if (!this.salesData) {
      return false;
    }
    if (this.salesData.sales.length === 0) {
      return false;
    }
    return true;
  }

  public getMessage(key: string): string {
    return this.i18nService.getMessage(key);
  }
}
