import {
  HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './api/auth.service';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
  ) { }

  private addAuthorizationHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const token = this.authService.getAuthToken() || '';
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}`.trim() } });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the URL is used to make a authorization, void the process
    if (request.url.endsWith('jwt-auth/v1/token') || request.url.endsWith('kuelga/v1/register')) {
      return next.handle(request);
    }

    // Add the authorization header and make the request
    const updatedRequest = this.addAuthorizationHeader(request);
    return next.handle(updatedRequest).pipe(
      tap(evt => {
        if (
          evt.type === 4 &&
          (evt as any).body &&
          !(evt as any).body.success &&
          ((evt as any).body.code === 'jwt_auth_invalid_token' ||
          (evt as any).body.code === 'jwt_auth_user_not_found'
          )) {
          // Token invalid or expired, invalidate session
          this.authService.logout();
        }
      }),
    );
  }
}
