import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IData } from 'src/app/api/__types/user';
import { BehaviorUserService } from 'src/app/shared/behavior-user.service';
import { I18nService } from 'src/app/shared/i18n.service';
import { I18nMessages } from 'src/app/shared/__types';

@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.component.html',
  styleUrls: ['./pending-approval.component.scss']
})
export class PendingApprovalComponent implements OnInit, OnDestroy {
  $behaviorUser: Subscription;

  currentLanguage: string;
  userProfile: IData;

  constructor(
    private behaviorUserService: BehaviorUserService,
    private i18nService: I18nService,
  ) { }

  ngOnInit(): void {
    this.$behaviorUser = this.behaviorUserService.currentMessage.subscribe(msg => this.userProfile = msg);
    this.currentLanguage = this.i18nService.getLanguage();
  }

  ngOnDestroy(): void {
    this.$behaviorUser.unsubscribe();
  }

  public changeLanguage(key: string): void {
    this.i18nService.setLanguage(key);
    this.currentLanguage = key;
  }

  public getMessage(key: keyof I18nMessages | string): string {
    return this.i18nService.getMessage(key);
  }

}
