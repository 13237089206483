<div *ngIf="loading" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
  <img height="160" src="../../../assets/img/animation_500_knfk9kn8.gif"/>
</div>

<div fxLayout="column" fxFlexFill *ngIf="!loading">
  <mat-toolbar class="navbar-color mat-elevation-z8 p-0" fxLayout="row" fxLayoutAlign="start stretch">

    <!-- <button mat-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button> -->

    <button mat-button routerLinkActive="router-link-active">
      Kuelga
    </button>

    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="pl-3">
      <span fxHide fxShow.xs>FX: XS</span>
      <span fxHide fxShow.sm>FX: SM</span>
      <span fxHide fxShow.md>FX: MD</span>
      <span fxHide fxShow.lg>FX: LG</span>
      <span fxHide fxShow.xl>FX: XL</span>
    </div> -->

    <span fxFlex></span>

    <button mat-button (click)="changeLanguage(currentLanguage === 'es' ? 'en' : 'es')">
      <mat-icon>language</mat-icon> {{ currentLanguage }}
    </button>

    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!--
      <button mat-menu-item>
        <mat-icon>admin_panel_settings</mat-icon>
        <span>{{ getMessage('accountSettings') }}</span>
      </button>
      -->

      <button mat-menu-item (click)="logout()">
        <mat-icon>login</mat-icon>
        <span>{{ getMessage('logout') }}</span>
      </button>
    </mat-menu>

    <!-- <div fxLayout="row" fxShow="false" fxShow.gt-sm>
      <button mat-button routerLink="/page1" routerLinkActive="router-link-active no-border">Page 1</button>
      <button mat-button routerLink="/page2" routerLinkActive="router-link-active no-border">Page 2</button>
      <button mat-button routerLink="/page3" routerLinkActive="router-link-active no-border">Page 3</button>
      <button mat-button routerLink="/page4" routerLinkActive="router-link-active no-border">Page 4</button>
      <button mat-button routerLink="/page5" routerLinkActive="router-link-active no-border">Page 5</button>
    </div> -->

    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User profile actions">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>admin_panel_settings</mat-icon>
        <span>{{ getMessage('accountSettings') }}</span>
      </button>

      <button mat-menu-item (click)="logout()">
        <mat-icon>login</mat-icon>
        <span>{{ getMessage('logout') }}</span>
      </button>
    </mat-menu> -->
  </mat-toolbar>

  <mat-drawer-container fxFlex autosize class="background">
    <!-- <mat-drawer #drawer>
      <div fxLayout="column">
        <button mat-button routerLink="/page1" routerLinkActive="selected no-border txt-bold" fxLayoutAlign="start" class="py-2 pr-5">
          <mat-icon class="mr-2">filter_1</mat-icon>Page 1</button>
        <mat-divider></mat-divider>
        <button mat-button routerLink="/page2" routerLinkActive="selected no-border txt-bold" fxLayoutAlign="start" class="py-2 pr-5">
          <mat-icon class="mr-2">filter_2</mat-icon>Page 2</button>
        <mat-divider></mat-divider>
        <button mat-button routerLink="/page3" routerLinkActive="selected no-border txt-bold" fxLayoutAlign="start" class="py-2 pr-5">
          <mat-icon class="mr-2">filter_3</mat-icon>Page 3</button>
        <mat-divider></mat-divider>
        <button mat-button routerLink="/page4" routerLinkActive="selected no-border txt-bold" fxLayoutAlign="start" class="py-2 pr-5">
          <mat-icon class="mr-2">filter_4</mat-icon>Page 4</button>
        <mat-divider></mat-divider>
        <button mat-button routerLink="/page5" routerLinkActive="selected no-border txt-bold" fxLayoutAlign="start" class="py-2 pr-5">
          <mat-icon class="mr-2">filter_5</mat-icon>Page 5</button>
        <mat-divider></mat-divider>
        <button mat-button fxLayoutAlign="start" class="py-2 pr-5">
          <mat-icon class="mr-2">filter</mat-icon>Page ...</button>
        <mat-divider></mat-divider>
      </div>
    </mat-drawer> -->

    <!--PAGES-->
    <div style="display: inline;">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>
