import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/api/auth.service';
import { UserService } from 'src/app/api/user.service';
import { IData } from 'src/app/api/__types/user';
import { BehaviorUserService } from 'src/app/shared/behavior-user.service';
import { I18nService } from 'src/app/shared/i18n.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  // Properties
  loading = true;
  $userProfile: Subscription;

  userProfile: IData | null = null;
  currentLanguage: string;
  showLoader: boolean;
  lottieOptions: AnimationOptions = {
    path: '/assets/46208-loading-spinner.json',
  };

  constructor(
    private i18nService: I18nService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private behaviorUserService: BehaviorUserService,
  ) { }

  ngOnInit(): void {
    this.currentLanguage = this.i18nService.getLanguage();
    this.showLoader = true;
    this.$userProfile = this.userService.getUserProfile().pipe(
      map(evt => {
        const data = evt.data;
        this.behaviorUserService.changeMessage(data);
        return data;
      }),
      tap((evt) => {
        switch (evt.role) {
          case 'r:1':
          case 'migrated':
            this.router.navigate(['/home/onboarding']);
            this.loading = false;
            break;

          case 'r:0':
            this.router.navigate(['/home/pending-approval']);
            this.loading = false;
            break;

          case 'artist':
            this.loading = false;
            break;

          default:
            // Invalid role
            this.authService.logout();
            break;
        }
      }),
      tap(() => this.showLoader = false),
    ).subscribe(data => this.userProfile = data);
  }

  ngOnDestroy(): void {
    this.$userProfile.unsubscribe();
  }

  public changeLanguage(key: string): void {
    this.i18nService.setLanguage(key);
    this.currentLanguage = key;
  }

  public getMessage(key: string): string {
    return this.i18nService.getMessage(key);
  }

  public logout(): void {
    this.authService.logout();
  }
}
