<div style="display: flex; flex-direction: column; align-items: center;">
  <input type="file" (change)="fileChangeEvent($event)" accept="image/*" hidden="true" #avatarInput />
  <image-cropper
    style="max-height: 200px;"
    [imageChangedEvent]="data.imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="2/2"
    [resizeToWidth]="400"
    [hideResizeSquares]="true"
    format="jpeg"
    (imageCropped)="imageCropped($event)"></image-cropper>

    <div style="margin-top: 24px; display: flex;">
      <button mat-button style="margin-right: 16px;" (click)="avatarInput.click()">Cambiar de foto</button>
      <button mat-button [mat-dialog-close]="avatarRes" color="primary">Seleccionar</button>
    </div>
</div>
