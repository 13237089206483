import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ChartComponent } from 'src/app/components/chart/chart.component';
import { I18nService } from 'src/app/shared/i18n.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(ChartComponent)
  private charComponent: ChartComponent;

  dateRange: { start: Date, end: Date } = { end: null, start: null };

  constructor(
    private i18nService: I18nService,
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  updateRange(type: 'start' | 'end', event: MatDatepickerInputEvent<Date>): void {
    if (!event.value) {
      return;
    }

    if (type === 'start') {
      event.value.setHours(0, 0, 0, 0);
    }

    if (type === 'end') {
      event.value.setHours(23, 59, 59, 59);
    }
    this.dateRange[type] = event.value;
  }

  public getReports(): void {
    if (this.dateRange.start && this.dateRange.end) {
      this.charComponent.getReports(
        Math.floor(this.dateRange.start.getTime() / 1000),
        Math.floor(this.dateRange.end.getTime() / 1000),
      );
    }
  }

  public getMessage(key: string): string {
    return this.i18nService.getMessage(key);
  }
}
