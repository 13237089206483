<div class="main-wrapper">
  <mat-card class="login-wrapper">
    <div style="text-align: center; margin-bottom: 16px;">
      <img width="100" src="../../../assets/img/kuelga_logo.svg">
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-form-field appearance="outline" class="login-input">
        <mat-label>{{ getMessage('email') }}</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>

      <mat-form-field appearance="outline" class="login-input">
        <mat-label>{{ getMessage('password') }}</mat-label>
        <input matInput type="password" formControlName="password">
      </mat-form-field>

      <div fxLayout="row" fxLayout.lt-md="column-reverse" fxLayoutAlign="space-between" fxLayoutAlign.lt-md="center center">
        <a mat-button href="https://kuelga.com/wp-login.php?action=lostpassword" [ngStyle.lt-md]="{'margin-top': '14px'}">{{ getMessage('forgot') }}</a>
        <button mat-flat-button color="primary" [disabled]="loginForm.disabled">{{ getMessage('login') }}</button>
      </div>
    </form>
  </mat-card>

  <div class="language-wrapper">
    <a mat-button [routerLink]="['/register']" [ngStyle.lt-md]="{'margin-top': '14px'}">{{ getMessage('signUp') }}</a>
  </div>

  <div class="language-wrapper">
    <button mat-button (click)="changeLanguage('en')">ENG</button>
    <button mat-button (click)="changeLanguage('es')">ESP</button>
  </div>
</div>
