import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/api/user.service';
import { IData } from 'src/app/api/__types/user';
import { BehaviorUserService } from 'src/app/shared/behavior-user.service';
import { countries } from 'src/app/shared/helpers/countries';
import { paymentMethods, paymentGroups } from 'src/app/shared/helpers/paymentMethods';
import { I18nService } from 'src/app/shared/i18n.service';
import { I18nMessages } from 'src/app/shared/__types';
import { IPaymentForm, IPaymentGroup, IPaymentMethod } from 'src/app/shared/__types/payment';
import { AvatarCropperComponent } from './avatar-cropper/avatar-cropper.component';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit, OnDestroy {
  // Properties
  $behaviorUser: Subscription;
  $makeR1User: Subscription;
  $updateUser: Subscription;

  userProfile: IData;
  currentLanguage: string;
  countriesList = countries;
  paymentGroupsList = paymentGroups;
  paymentMethodsList = paymentMethods;
  imageChangedEvent: any = '';

  biographyFormGroup: FormGroup;
  generalInfoFormGroup: FormGroup;
  paymentMethodFormGroup: FormGroup;
  paymentMethodForms: FormGroup;
  paymentMethodInfo: IPaymentForm[] = [];

  @ViewChild('avatarInput') avatarInput: ElementRef;

  constructor(
    private behaviorUserService: BehaviorUserService,
    private i18nService: I18nService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.$behaviorUser = this.behaviorUserService.currentMessage.subscribe(msg => this.userProfile = msg);
    this.currentLanguage = this.i18nService.getLanguage();

    this.biographyFormGroup = this.formBuilder.group({
      profile_avatar: ['', Validators.required],
      biography: ['', [Validators.required, Validators.minLength(50), Validators.maxLength(1000)]],
    });

    this.generalInfoFormGroup = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.maxLength(60), Validators.minLength(2)]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required, Validators.maxLength(60)]],
      phone: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(12)]],
      birthDate: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      ...(this.userProfile.role === 'migrated' ? {
        password: ['', [Validators.required, Validators.minLength(8)]],
        rePassword: ['', [Validators.required, Validators.minLength(8)]],
      } : {}),
    });

    this.paymentMethodFormGroup = this.formBuilder.group({
      paymentMethodId: ['', [Validators.required]],
    });

    this.paymentMethodForms = this.formBuilder.group({});
  }

  ngOnDestroy(): void {
    this.$behaviorUser.unsubscribe();

    if (this.$makeR1User) {
      this.$makeR1User.unsubscribe();
    }

    if (this.$updateUser) {
      this.$updateUser.unsubscribe();
    }
  }

  private makeR1(): void {
    if (this.userProfile.role === 'migrated') {
      this.$makeR1User = this.userService.makeMigratedArtist().subscribe(
        res => {
          // To force a new call for get user profile and get the new role
          window.location.href = '/home';
        },
        err => { },
      );
      return;
    }

    this.$makeR1User = this.userService.makeR1Role().subscribe(
      res => { window.location.href = '/home'; },
      err => { },
    );
  }

  public changeLanguage(key: string): void {
    this.i18nService.setLanguage(key);
    this.currentLanguage = key;
  }

  public getMessage(key: keyof I18nMessages | string): string {
    return this.i18nService.getMessage(key);
  }

  public openDialog(): void {
    if (!this.imageChangedEvent.target.value) {
      return;
    }

    const dialogRef = this.dialog.open(AvatarCropperComponent, {
      width: '600px',
      data: { imageChangedEvent: this.imageChangedEvent },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.avatarInput.nativeElement.value = '';
      if (!result) {
        return;
      }
      this.biographyFormGroup.setValue({...this.biographyFormGroup.getRawValue(), ...{ profile_avatar: result.croppedImage }});
    });
  }

  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.openDialog();
  }

  public getPaymentsByGroup(groupId: string): Array<IPaymentMethod> {
    if (typeof groupId !== 'string') {
      return [];
    }
    return this.paymentMethodsList.filter(paymentMethod => paymentMethod.groupId === groupId);
  }

  public getGroupInfo(groupId: string): IPaymentGroup {
    return this.paymentGroupsList.find(group => group.id === groupId);
  }

  public updateUserInfo(): void {
    if (
      !this.biographyFormGroup.valid ||
      !this.generalInfoFormGroup.valid ||
      !this.paymentMethodFormGroup.valid ||
      !this.paymentMethodForms.valid)
    {
      return;
    }

    const data = {
      ...this.biographyFormGroup.getRawValue(),
      ...this.generalInfoFormGroup.getRawValue(),
      ...this.paymentMethodFormGroup.getRawValue(),
      paymentInfo: '',
    };

    if (!(data.birthDate instanceof Date && !isNaN(data.birthDate))) {
      return;
    }

    data.birthDate = data.birthDate.toISOString();

    for (const [key, value] of Object.entries(this.paymentMethodForms.getRawValue())) {
      data.paymentInfo = `${data.paymentInfo}${data.paymentInfo ? '::' : ''}${key}: ${value}`.trim();
    }

    this.$updateUser = this.userService.updateUser(data).subscribe(
      res => { this.makeR1(); },
      err => { },
    );
  }

  public buildBankForm(selectedMethod: IPaymentMethod): void {
    for (const [controlName] of Object.entries(this.paymentMethodForms.controls)) {
      this.paymentMethodForms.removeControl(controlName);
      const idx = this.paymentMethodInfo.findIndex(info => info.name === controlName);
      if (idx > -1) {
        this.paymentMethodInfo.splice(idx, 1);
      }
    }

    for (const form of selectedMethod.forms) {
      this.paymentMethodForms.addControl(form.name, new FormControl('', form.required ? Validators.required : undefined));
      this.paymentMethodInfo.push(form);
    }
  }

  public getPaymentFormInfo(name: string): IPaymentForm {
    const paymentInfo = this.paymentMethodInfo.find(methodInfo => methodInfo.name === name);

    if (paymentInfo) {
      return paymentInfo;
    }
  }

  public continueBioForm(): boolean {
    if (this.userProfile.role === 'migrated' && this.generalInfoFormGroup.valid) {
      if (this.generalInfoFormGroup.value.password !== this.generalInfoFormGroup.value.rePassword) {
        return true;
      }
    }
    return !this.generalInfoFormGroup.valid;
  }
}
