<div [ngStyle]="{'padding': '12px'}">
  <div style="display: flex; justify-content: flex-end; align-items: center;" [ngStyle.lt-md]="{'flex-direction': 'column', 'align-items': 'flex-start'}">
    <mat-form-field appearance="outline" id="chart-range" style="margin-right: 16px;">
      <mat-label>{{ getMessage('enterDateRange') }}</mat-label>
      <mat-date-range-input [rangePicker]="dp3">
        <input matStartDate (dateChange)="updateRange('start', $event)" placeholder="Start date">
        <input matEndDate (dateChange)="updateRange('end', $event)" placeholder="End date">
      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
      <mat-date-range-picker #dp3 disabled="false"></mat-date-range-picker>
    </mat-form-field>

    <button mat-stroked-button color="primary" [ngStyle.lt-md]="{'margin-top': '12px'}" (click)="getReports()">{{ getMessage('getReport') }}</button>
  </div>

  <app-chart></app-chart>
</div>
