import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IAuthResponse } from './__types';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly AUTH_LOCAL_STORAGE_KEY = 'auth_token';

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  private setAuthToken(token: string): void {
    localStorage.setItem(this.AUTH_LOCAL_STORAGE_KEY, token);
  }

  public getAuthToken(): string | null {
    return localStorage.getItem(this.AUTH_LOCAL_STORAGE_KEY);
  }

  public isLoggedIn(): boolean {
    return this.getAuthToken() ? true : false;
  }

  public login({ email, password }: { email: string, password: string }): Observable<IAuthResponse> {
    const url = `${environment.baseApiUrl}jwt-auth/v1/token`;
    const data = {username: email, password};

    return new Observable<IAuthResponse>(observer => {
      this.http.post<IAuthResponse>(url, data).subscribe(
        res => {
          if (res.statusCode !== 200) {
            observer.error({
              statusCode: res.statusCode,
              errorCode: res.code,
            });
            observer.complete();
            return;
          }

          this.setAuthToken(res.data.token);
          observer.next(res);
          observer.complete();
        },
        err => {
          observer.error(false);
        },
      );
    });
  }

  public logout(): void {
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
