import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISalesRawResponse } from './__types/sales';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(
    private http: HttpClient,
  ) { }

  public getSales(start: number, end: number): Observable<ISalesRawResponse> {
    const url = `${environment.baseApiUrl}jwt-auth/v1/artist/sales?startat=${start}&endat=${end}`;

    return new Observable<ISalesRawResponse>(observer => {
      this.http.get<ISalesRawResponse>(url).subscribe(
        res => {
          if (res.statusCode !== 200) {
            observer.error({
              statusCode: res.statusCode,
              errorCode: res.code,
            });
            observer.complete();
            return;
          }

          observer.next(res);
          observer.complete();
        },
        err => {
          observer.error({ code: err.error.code, message: err.error.message });
        },
      );
    });
  }
}
