<!-- Header -->
<div class="onboarding-wrapper">
  <div class="header">
    <h1 style="margin-bottom: 8px;">{{ getMessage('onboardingTitle') }}</h1>
    <span class="mat-subheading-2">{{ getMessage('onboardingDescription') }}</span>
  </div>
</div>


<div class="container">
  <!-- Main -->
  <div class="onboarding-wrapper">
    <div class="onboarding">
      <mat-vertical-stepper [linear]="true" #stepper>

        <!-- Avatar & Bio -->
        <mat-step [stepControl]="biographyFormGroup">
          <form [formGroup]="biographyFormGroup">
            <ng-template matStepLabel>{{ getMessage('biographyTitle') }}</ng-template>
            <div class="stepper-wrapper" [fxLayout]="'row'" [fxLayout.lt-md]="'column'" [fxLayoutAlign]="'space-between'">
              <div [fxLayout]="'row'" [fxFlexAlign]="'center'" [fxFlexAlign.lt-md]="'start'">
                <img class="avatar" [src]="biographyFormGroup.getRawValue()['profile_avatar'] || '../../../assets/img/avatar-placeholder.svg'">
                <input type="file" (change)="fileChangeEvent($event)" accept="image/*" hidden="true" #avatarInput />
                <div style="margin-left: 20px;">
                  <p>{{ getMessage('chooseAvatar') }}</p>
                  <a mat-stroked-button color="primary" (click)="avatarInput.click()">{{ biographyFormGroup.getRawValue()['avatar'] ? getMessage('changeYourAvatar') : getMessage('uploadImage') }}</a>
                </div>
              </div>

              <div class="biography-description" [ngStyle.lt-md]="{'margin-top': '24px'}">{{ getMessage('biographyDescription') }}</div>
            </div>

            <div class="stepper-wrapper" [ngStyle.lt-md]="{'margin-top': '24px'}">
              <mat-form-field appearance="outline" style="width: 100%;">
                <textarea rows="3" matInput formControlName="biography" #biography maxlength="1000" placeholder="{{getMessage('biographyPlaceholder')}}"></textarea>
                <mat-error *ngIf="biographyFormGroup.get('biography').invalid">{{ getMessage('biographyErrorMessage') }}</mat-error>
                <mat-hint align="end">{{biography.value.length}} / 1000</mat-hint>
              </mat-form-field>
            </div>

            <div class="stepper-footer">
              <button mat-button matStepperNext [disabled]="!biographyFormGroup.valid">{{ getMessage('nextStep') }}</button>
            </div>
          </form>
        </mat-step>
        <!-- Avatar & Bio -->

        <!-- General Info -->
        <mat-step [stepControl]="generalInfoFormGroup">
          <form [formGroup]="generalInfoFormGroup">
            <ng-template matStepLabel>{{ getMessage('generalInfoTitle') }}</ng-template>
            <div class="stepper-wrapper">
              <div [fxLayout]="'row'" [fxLayout.lt-md]="'column'" [fxLayoutAlign]="'space-between'">
                <mat-form-field appearance="outline" style="width: 100%; max-width: 401px;" [ngStyle.lt-md]="{'max-width': '100%'}">
                  <mat-label>{{ getMessage('fullName') }}</mat-label>
                  <input matInput formControlName="fullName" maxlength="60">
                </mat-form-field>
              </div>

              <mat-divider></mat-divider>

              <div [fxLayout]="'row'" [fxLayout.lt-md]="'column'" [fxLayoutAlign]="'space-between'" style="margin-top: 24px;">
                <mat-form-field appearance="outline" style="width: 100%; max-width: 255px;" [ngStyle.lt-md]="{'max-width': '100%'}">
                  <mat-label>{{ getMessage('country') }}</mat-label>
                  <mat-select formControlName="country">
                    <mat-option *ngFor="let country of countriesList" [value]="country.code">{{ country.name }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100%; max-width: 263px;" [ngStyle.lt-md]="{'max-width': '100%'}">
                  <mat-label>{{ getMessage('city') }}</mat-label>
                  <input matInput formControlName="city" maxlength="60">
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100%; max-width: 255px;" [ngStyle.lt-md]="{'max-width': '100%'}">
                  <mat-label>{{ getMessage('phoneNumber') }}</mat-label>
                  <input matInput formControlName="phone" maxlength="12" placeholder="Ex: +51999999999">
                </mat-form-field>
              </div>

              <mat-divider></mat-divider>

              <div [fxLayout]="'row'" [fxLayout.lt-md]="'column'" [fxLayoutAlign]="'space-between'" style="margin-top: 24px;">
                <mat-form-field appearance="outline" style="width: 100%; max-width: 255px;" [ngStyle.lt-md]="{'max-width': '100%'}">
                  <mat-label>{{ getMessage('birthDate') }}</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="birthDate">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100%; max-width: 263px;" [ngStyle.lt-md]="{'max-width': '100%'}">
                  <mat-label>{{ getMessage('gender') }}</mat-label>
                  <mat-select formControlName="gender">
                    <mat-option [value]="'male'">{{ getMessage('male') }}</mat-option>
                    <mat-option [value]="'female'">{{ getMessage('female') }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 100%; max-width: 255px;" [ngStyle.lt-md]="{'max-width': '100%'}">
                  <mat-label>{{ getMessage('email') }}</mat-label>
                  <input matInput [value]="userProfile?.email || ''" readonly>
                </mat-form-field>
              </div>

              <mat-divider *ngIf="userProfile.role === 'migrated'"></mat-divider>

              <div *ngIf="userProfile.role === 'migrated'" [fxLayout]="'row'" [fxLayout.lt-md]="'column'" style="margin-top: 24px;">
                <mat-form-field appearance="outline" style="width: 100%; max-width: 255px; margin-right: 26px;" [ngStyle.lt-md]="{'max-width': '100%', 'margin-right': '0px'}">
                  <mat-label>{{ getMessage('password') }}</mat-label>
                  <input type="password" matInput formControlName="password" maxlength="12">
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100%; max-width: 255px;" [ngStyle.lt-md]="{'max-width': '100%'}">
                  <mat-label>{{ getMessage('rePassword') }}</mat-label>
                  <input type="password" matInput formControlName="rePassword" maxlength="12">
                </mat-form-field>
              </div>
            </div>

            <div class="stepper-footer">
              <button mat-button matStepperPrevious>{{ getMessage('prevStep') }}</button>
              <button mat-button matStepperNext [disabled]="continueBioForm()">{{ getMessage('nextStep') }}</button>
            </div>
          </form>
        </mat-step>
        <!-- Avatar & Bio -->

        <!-- Payment Method -->
        <mat-step [stepControl]="paymentMethodFormGroup">
          <form [formGroup]="paymentMethodFormGroup">
            <ng-template matStepLabel>{{ getMessage('receiptInfo') }}</ng-template>
            <div class="stepper-wrapper">

              <mat-radio-group class="payment-method-group" formControlName="paymentMethodId">
                <div *ngFor="let paymentMethod of paymentMethodsList; let first = first;" class="payment-method" [style]="{'margin-top': '16px', 'margin-right': '8px'}">
                  <div style="margin-bottom: 14px;">
                    <mat-radio-button [value]="paymentMethod.id" (change)="buildBankForm(paymentMethod)">{{ getMessage(paymentMethod.name) }}</mat-radio-button>
                  </div>
                  <div class="payment-method-image" [style.background-image]="'url(../../../assets/img/'+ paymentMethod.imageUrl +')'"></div>
                  <span style="text-align: right; margin-top: 8px; color: #9E9E9E">{{ getGroupInfo(paymentMethod.groupId).i18nKey }}</span>
                </div>
              </mat-radio-group>

              <form [formGroup]="paymentMethodForms" style="margin-top: 24px; margin-bottom: 8px;">
                <ng-template ngFor let-control [ngForOf]="paymentMethodForms.controls | keyvalue">
                  <mat-form-field appearance="outline" style="width: 100%; max-width: 401px; margin-right: 8px;" [ngStyle.lt-md]="{'max-width': '100%', 'margin-right': '0px'}">
                    <mat-label>{{ getMessage(getPaymentFormInfo(control.key).i18nLabel) }}</mat-label>
                    <input matInput [formControlName]="control.key" maxlength="60">
                  </mat-form-field>
                </ng-template>
              </form>
            </div>

            <div class="stepper-footer">
              <button mat-button matStepperPrevious>{{ getMessage('prevStep') }}</button>
              <button mat-button matStepperNext (click)="updateUserInfo()" [disabled]="!paymentMethodFormGroup.valid || !paymentMethodForms.valid">{{ getMessage('submit') }}</button>
            </div>
          </form>
        </mat-step>
        <!-- Payment Method -->

      </mat-vertical-stepper>
    </div>
  </div>
</div>
